@tailwind base;
input, textarea {
  box-sizing: content-box;
  line-height: 1.5;
}

button:focus {
  outline: unset !important;
}

code {
  background: var(--background-app-bar);
  border-radius: var(--border-radius);
  color: var(--text-color);
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 85%;
  padding: 0.2em 0.4em;
}

blockquote {
  background: rgba(var(--color-primary), 0.1);
  border-left: 3px solid theme("backgroundColor.primary");
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  margin: 1em 0 1.5em;
  padding: 1em 1.5em;
}
blockquote > * {
  margin: 0;
}

@tailwind components;
@tailwind utilities;
.container {
  padding-left: var(--padding-gutter);
  padding-right: var(--padding-gutter);
}
@screen lg {
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: theme("screens.lg");
    width: 100%;
  }
}

.card {
  @apply rounded shadow bg-foreground;
}

.avatar {
  @apply h-10 w-10 object-cover rounded-full bg-hover;
}

.hidden-input {
  @apply border-2 border-solid border-transparent py-1 px-3 rounded;
}
.hidden-input:focus {
  @apply border-primary outline-none;
}
.hidden-input.empty {
  @apply border-primary;
}

.list-item {
  @apply rounded h-12 px-4 cursor-pointer;
}

.list-item:hover {
  @apply bg-hover;
}

.bg-pattern {
  background: linear-gradient(135deg, var(--background-base) 22px, var(--background-hover) 22px, var(--background-hover) 24px, transparent 24px, transparent 67px, var(--background-hover) 67px, var(--background-hover) 69px, transparent 69px), linear-gradient(225deg, var(--background-base) 22px, var(--background-hover) 22px, var(--background-hover) 24px, transparent 24px, transparent 67px, var(--background-hover) 67px, var(--background-hover) 69px, transparent 69px) 0 64px;
  background-color: var(--background-base);
  background-size: 64px 128px;
}

.trans-ease-out {
  transition: all 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.trans-shadow {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.display-4 {
  font: 300 112px / 112px var(--font);
  letter-spacing: -0.05em;
}

.display-3 {
  font: 400 56px / 56px var(--font);
  letter-spacing: -0.02em;
}

.display-2 {
  font: 400 45px / 48px var(--font);
  letter-spacing: -0.005em;
}

.display-1, h1 {
  font: 400 34px / 40px var(--font);
  letter-spacing: normal;
}

.headline, h2 {
  font: 400 24px / 32px var(--font);
  letter-spacing: -0.019em;
}

.title, h3 {
  font: 500 18px / 26px var(--font);
  letter-spacing: -0.014em;
}

.subheading-2, h4 {
  font: 400 16px / 28px var(--font);
  letter-spacing: -0.011em;
}

.subheading-1, h5 {
  font: 400 15px / 24px var(--font);
  letter-spacing: -0.009em;
}

.body-2, h6 {
  font: 500 14px / 24px var(--font);
  letter-spacing: -0.006em;
}

.body-1, p {
  font: 400 14px / 20px var(--font);
  letter-spacing: -0.006em;
}

.caption {
  font: 400 12px / 20px var(--font);
  letter-spacing: 0;
}