@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "@vex/styles/core";


:root {
    --color-accent: 255, 64, 129;
    --color-primary: 92, 119, 255;
    --color-warning: 255, 87, 34;
    --color-success: 0, 150, 136;
}

body {
    background: #f0f0f0;
}

.avatar {
    overflow: hidden;
}

.headline {
    font-weight: bold !important;
}

.chip-color {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.align-right {
   text-align: right !important;
}
.align-center {
   text-align: center !important;
}

.text-accent {
    color: rgba(var(--color-accent));
}

// datepicker input size
.datepicker-flex .mat-form-field-flex {
    align-items: flex-start;
}

.lightbox-dialog-container {
    .mat-dialog-container {
        padding: 0px !important;
    }
}

.vex-toolbar {
    z-index: 20 !important;
}